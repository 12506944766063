import Pristine from '~/globals/form-validation/pristine-modified/pristine';

// Default pristine validation configuration
// https://github.com/sha256/Pristine
const defaultPristineConfig = {
  // selector class of the parent element where the target `errorClass` below will be added
  classTo: 'js-validation-parent',
  // class that gets assigned to the parent element when the field has an error
  errorClass: 'has-validation-error',

  // selector class of the parent element where error text element is appended
  errorTextParent: 'js-validation-parent',
  // class of the error text element
  errorTextClass: 'validation-error',
};

/*
  Helper method to initialize Pristine form validation with our default configuration.

  ```
    import { PristineValidation } from '~/globals/form-validation/form-validation';
    const pristine = PristineValidation(formElement);
  ```
*/
export const PristineValidation = (form, config = {}, live = true) => {
  const pristineConfig = { ...defaultPristineConfig, ...config };
  const pristine = new Pristine(form, pristineConfig, live);

  return pristine;
};
